function parseLink(rawHit) {
  if (rawHit.page_type === "zendesk_article") {
    return rawHit.custom_url
  }
  return rawHit.url_path || `/${rawHit.custom_url}/`
}

function parsePageText(rawText) {
  if (Array.isArray(rawText)) {
    return rawText.join(" ")
  }
  return rawText
}

function parseRawSearchResult({ rawHit, includePageText = false }) {
  const parsed = {
    id: rawHit.objectID,
    name: rawHit.name,
    highlightedName: rawHit._highlightResult.name.value,
    permalink: parseLink(rawHit),
    pageType: rawHit.page_type,
    imagePath: rawHit.image,
    minPrice: rawHit.min_price,
    maxPrice: rawHit.max_price,
    marketingTaxons: rawHit.marketing_taxons,
    oilType: rawHit.oil_type,
    rating: rawHit.rating,
    newProduct: rawHit.new_product,
    metaDescription: rawHit.meta_description,
    imageSrcSet: rawHit.image_src_set,
  }
  if (includePageText) {
    parsed.pageText = parsePageText(rawHit.page_text)
  }
  return parsed
}

export default parseRawSearchResult
