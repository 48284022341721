import { createProductTags } from "~/lib/createProductTags.js"

const NoImage = "/images/no-image.png"

const IMAGE_MAP = {
  alchemy_page: "/images/article.png",
  zendesk_article: "/images/faq.png",
}

export default {
  data() {
    return {
      loadingError: false,
    }
  },
  methods: {
    setFallBackImg() {
      this.loadingError = true
    },
  },
  props: {
    imagePath: {
      type: String,
      default: "~/assets/images/no-image.png",
    },
    name: {
      type: String,
      default: null,
    },
    oilType: {
      type: String,
      default: null,
    },
    permalink: {
      type: String,
      default: null,
    },
    newProduct: {
      type: Boolean,
      default: false,
    },
    highlightedName: {
      type: String,
      default: null,
    },
    pageText: {
      type: String,
      default: null,
    },
    rating: {
      type: Number,
      required: false,
      default: null,
    },
    minPrice: {
      type: String,
      default: null,
    },
    maxPrice: {
      type: String,
      default: null,
    },
    marketingTaxons: {
      type: Array,
      default: () => [],
    },
    imageSrcSet: {
      type: Array,
      default: () => [],
    },
    metaDescription: {
      type: String,
      default: null,
    },
    pageType: {
      type: String,
      default: null,
    },
  },
  computed: {
    productTags() {
      return createProductTags(this.marketingTaxons)
    },
    formatDesc() {
      if (this.pageText.length > 125) {
        return this.pageText.substring(0, 125) + "..."
      }
      return this.pageText
    },
    formatMinPrice() {
      return parseFloat(this.minPrice).toFixed(2)
    },
    formatMaxPrice() {
      return parseFloat(this.maxPrice).toFixed(2)
    },
    hasValidRating() {
      return typeof this.rating === "number"
    },
    roundRating() {
      return Math.round(this.rating)
    },
    formatLabel() {
      return `This product has a rating of ${this.roundRating} out of 5 stars.`
    },
    priceLabel() {
      return `This product starts at $${this.formatMinPrice}.`
    },
    priceRange() {
      return `This product ranges from $${this.formatMinPrice} to $${this.formatMaxPrice}.`
    },
    fallbackImage() {
      return IMAGE_MAP[this.pageType] || NoImage
    },
  },
}
